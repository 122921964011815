import { ICeRichTextFields } from 'types/generated/contentful';

import { RichTextBlock } from './RichTextBlock';

export type RichTextProps = ICeRichTextFields;
export const RichText: React.FC<RichTextProps> = ({ textContent }) => {
  // @TODO: Implement bulletPointColor
  // bulletPointColor: 'blue' | 'orange' | 'default';

  return <RichTextBlock document={textContent} />;
};
