import { styled } from '@mui/material/styles';
import { Container } from '@mui/material';
import { Document } from '@contentful/rich-text-types';

import { RichTextContent } from './RichTextContent';

const RichTextBlockWrapper = styled('div')({
  whiteSpace: 'pre-wrap',
  marginTop: '1rem',
  marginBottom: '1rem',
});

export const RichTextBlock = ({ document }: { document?: Document }) => {
  return (
    <Container>
      <RichTextBlockWrapper>
        <RichTextContent document={document}></RichTextContent>
      </RichTextBlockWrapper>
    </Container>
  );
};
